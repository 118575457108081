/*
 * @Description:
 * @Autor: shanbian
 * @Date: 2023-03-02 13:43:33
 * @LastEditors: shanbian
 * @LastEditTime: 2023-03-02 14:35:58
 */
// 接口状态
export enum INTERFACE_TYPE_ENUM {
  SUCCESS = 200, //成功
  ERROR = 500,
}

//发射的事件
export enum EVENT_TYPE_ENUM {
  CLOSE = "close-dialog", //关闭弹框
  EDIT = "edit-success", //编辑成功
  ADD = "add-success", //添加成功
}
