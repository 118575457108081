import { defineComponent, onMounted, reactive, toRefs, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getServiceDetail, getServiceSeriesDetail } from '@/http/service';
import { ElMessage } from 'element-plus';
import { imgUrl } from '@/utils/urlManage';
import { basicMixin } from "@/mixin/basicMixin";
import { ArrowRight } from '@element-plus/icons-vue';
import { getOrgCodeByConfig } from '@/http/home';
import { getGeneralInfo } from '@/http/about';
import { INTERFACE_TYPE_ENUM } from '@/utils/constant';
export default defineComponent({
  mixins: [basicMixin],

  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      code,
      seriesid
    } = route.query;
    const info = reactive({
      seriesNo: '',
      serviceDetail: {},
      ServiceSeriesDetail: {},
      seriesNoList: [],
      pathList: [],
      orgCode: sessionStorage.getItem('orgCode') || '',
      generalDate: ''
    });
    const methods = {
      // 获取产品详情
      getServiceDetailMethod(code) {
        getServiceDetail({
          'orgCode': info.orgCode,
          'code': code
        }).then(res => {
          if (res.data.code == 200) {
            console.log(res.data.data);
            info.serviceDetail = res.data.data;
            info.serviceDetail.hasOwnProperty('children') ? info.seriesNoList = res.data.data.children : '';
            methods.changeMeau();
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      // 获取产品序列号详情
      getServiceSeriesDetailMethod(seriesid) {
        info.ServiceSeriesDetail = {};
        getServiceSeriesDetail({
          'orgCode': info.orgCode,
          'code': seriesid
        }).then(res => {
          if (res.data.code == 200) {
            info.ServiceSeriesDetail = res.data.data;
            info.seriesNo = res.data.data.code;
          } else {
            ElMessage.error(res.data.message);
          }
        });
      },

      // 下载文件
      downloadDoc(row) {
        let exc = imgUrl(row.fileUrl).substring(imgUrl(row.fileUrl).lastIndexOf('.'));
        let fileName = row.name ? row.name : row.seriesNo;

        if (exc == '.pdf') {
          window.open(imgUrl(row.fileUrl));
        } else {
          let link = document.createElement("a");
          link.style.display = "none"; // link.href = fileUrl;

          link.href = imgUrl(row.fileUrl);
          link.setAttribute("download", fileName + exc);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } // basicMixin.methods.useBase64ByURL(imgUrl(row.url)).then((res: any) => {
        //   if (!res.blob) return;
        //   let url = window.URL.createObjectURL(new Blob([res.blob]));
        // let link = document.createElement("a");
        // link.style.display = "none";
        // // link.href = url;
        // link.href = imgUrl(row.url);
        // link.setAttribute("download",fileName + exc);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // })

      },

      /**
       * 头部路由
       */
      changeMeau() {
        let str = sessionStorage.getItem('deviceDetail');
        info.pathList = str ? JSON.parse(str) : [{
          title: "服务支持",
          path: "/service"
        }];

        if (!str) {
          info.pathList.push({
            title: info.serviceDetail.name,
            path: ''
          });
        }
      },

      goPath(url) {
        if (url) {
          router.push(url);
        }
      },

      // 获取相关宣传信息
      async getGeneralInfoMthod() {
        const res = await getGeneralInfo({
          orgCode: sessionStorage.getItem("orgCode") || "",
          typeCode: 4
        });

        if (res.data.code == INTERFACE_TYPE_ENUM.SUCCESS) {
          info.generalDate = res.data.data;
        } else {
          ElMessage.error(res.data.message);
        }
      }

    };
    onMounted(async () => {
      if (info.orgCode == "") {
        const res = await getOrgCodeByConfig({
          siteAddress: window.location.origin
        });

        if (res.data.code == 200) {
          sessionStorage.setItem("orgCode", res.data.data);
          info.orgCode = res.data.data;
          code ? methods.getServiceDetailMethod(code) : '';
          seriesid ? methods.getServiceSeriesDetailMethod(seriesid) : '';
          methods.getGeneralInfoMthod();
        }
      } else {
        code ? methods.getServiceDetailMethod(code) : '';
        seriesid ? methods.getServiceSeriesDetailMethod(seriesid) : '';
        methods.getGeneralInfoMthod();
      }
    });
    onUnmounted(() => {
      sessionStorage.removeItem('deviceDetail');
    });
    return { ...toRefs(info),
      imgUrl,
      ...methods,
      ArrowRight
    };
  }

});