/*
 * @Description:
 * @Autor: shanbian
 * @Date: 2023-02-09 17:54:13
 * @LastEditors: shanbian
 * @LastEditTime: 2023-08-21 20:07:17
 */
import $http from "./index";
import { ServiceType } from "@/types/service";
// 发送联系我们数据
export const sendContactUs = (data: ServiceType.ContactUsData) =>
  $http({
    url: `/api/public/website/leaveMessage/insert`,
    method: "post",
    data,
  });
// 资源下载列表
export const getDataDownloadList = () =>
  $http({
    url: `/service/data`,
    method: "get",
  });

//服务支持-产品详情[数据详情]
export const getServiceDetail = (data: { code: string,orgCode:string }) =>
  $http({
    url: `/api/public/website/supportProductDetail/getSupportProductDetail`,
    method: "post",
    data,
  });
//产品详情-序列产品[数据详情]
export const getServiceSeriesDetail = (data: { code: string,orgCode:string }) =>
  $http({
    url: `/api/public/website/supportSeriesDetail/getSupportSeriesDetail`,
    method: "post",
    data,
  });

export const getProductNav = (data:any) =>
  $http({
    url: `/api/public/website/supportType/getProductListByType`,
    method: "post",
    data
  });
