import $http from "./index";
import { AboutType } from "@/types/about";
// 核心优势category1
export const getCoreAdv = () =>
  $http({
    url: `/companyIntroduction/companyInfo/data/show`,
    method: "post",
  });

// 全部职位数据
export const getRecruitData = (data: any) =>
  $http({
    url: `api/public/website/contentRecruit/getContentRecruitPage`,
    method: "post",
    data,
  });

// 新闻数据---企业动态
export const getEnterpriseDynamic = (data: AboutType.NewsPaging) =>
  $http({
    url: `/api/public/website/contentNews/getContentNewsPage`,
    method: "post",
    data,
  });
//首页新闻
export const getLatestNews = (data: { orgCode: string }) =>
  $http({
    url: `/api/public/website/contentNews/getLatestNews`,
    method: "post",
    data,
  });

// 分页查询--typeCode类型：0：(补充说明)、1：(加入我们) 、2：(团队介绍)、3：(联系我们)、4：(相关宣传)
export const getGeneralInfo = (data: { typeCode: number; orgCode: string }) =>
  $http({
    url: `/api/public/website/generalInfo/getGeneralInfo`,
    method: "post",
    data,
  });
//查询公司介绍信息
export const getCompanyInfo = (data: { orgCode: string }) =>
  $http({
    url: `/api/public/website/companyInfo/getCompanyInfo`,
    method: "post",
    data,
  });
